div.topBanner{
    width: 100%;
    margin: 0 auto;
    padding: 15px 20px 30px;
    z-index: 9;
    position: relative;
    background-color: var(--custom-top-banner-bg-color);

    div.head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media all and (max-width:980px){
            flex-direction: column;
        }
        @media all and (max-width:768px){
           display: none;
        }
        span.contact{
            color: var(--text-color);
            span.icon{
                margin-right: 10px;
                display: inline-block;
            }
        }
        div.social{
            display: flex;
            align-items: center;
            @media all and (max-width:980px){
                margin-top: 15px;
            }
            span.label{
                margin-right: 10px;
                color: var(--text-color);
                font-weight: 600;
            }
            div.icons{
                display: flex;
                cursor: pointer;
                span.icon{
                    width: 35px;
                    height: 35px;
                    background-color: var(--white-color);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right:5px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
    div.body{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px auto 0;
        @media all and (max-width:768px){
           margin-top: 0;
           flex-direction: column;
         }

        div.imageContainer{
            width: 50%;
            margin-right: 150px;
            cursor: pointer;
            @media all and (max-width:1200px){
                width: 60%;
            }
            @media all and (max-width:980px){
                width: 80%;
                margin-right: 48px;
            }
            @media all and (max-width:768px){
                margin-right: 0;
                margin-bottom: 15px;
            }
            img{
                width: 100%;
                display: block;
            }
        }
        div.searchContainer{
            width: 20%;
            @media all and (max-width:980px){
                width: 41%;
            }
            @media all and (max-width:768px){
               width: 60%;
            }
            @media all and (max-width:480px){
                width: 90%;
             }
            input{
                padding: 6px 20px;
                border-radius: 30px;
                outline: none;
                border: none;
                display: block;
                width: 100%;
                &::placeholder{
                    font-weight: 700;
                    font-size: 15px;
                }
            }
        }
    }

}