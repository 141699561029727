div.ratingsAndReview {
    div.overlayModal {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 99;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div.container {
      z-index: 999;
      text-align: center;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--bg-color);
      width: 40%;
      margin: 0 auto;
      display: flex;
      padding: 20px 0;
      flex-direction: column;
      border-radius: 15px;
      @media all and (max-width: 1200px) {
        padding: 30px;
        width: 50%;
      }
      @media all and (max-width: 980px) {
          width: 60%;
        }
      @media all and (max-width: 768px) {
        width: 70%;
      }
      @media all and (max-width: 640px) {
        width: 90%;
        padding: 20px 10px;
      }
      div.closeIconContainer {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }
      h1.heading {
        font-size: 25px;
        margin-bottom: 6px;
        color: var(--text-color);
      }
      p {
        font-size: 12px;
        margin-bottom: 20px;
  
        span {
          font-weight: 600;
          margin-right: 6px;
        }
      }
      div.ratings {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 20px;
        position: relative;
        div.star {
          margin-right: 10px;
          transition: 0.3s ease-in-out;
          cursor: pointer;
          &.noMargin {
            margin-right: 0;
          }
          &:hover {
            transform: scale(1.3);
          }
          &.active {
            svg {
              fill: #fbe725;
            }
          }
          svg {
            width: 30px !important;
            height: 30px !important;
            fill: var(--grey-color);
          }
        }
        div.ratingValidation {
          padding: 3px 15px;
          background-color: #fbe725;
          position: absolute;
          bottom: -30px;
          left: 50%;
          transform: translateX(-50%);
          p {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 0;
          }
        }
      }
      div.review {
        border-top: 1px solid var(--border-color);
  
        padding: 30px 20px;
        textarea {
          width: 100%;
          border: none;
          outline: none;
          min-height: 100px;
          resize: none;
          border-radius: 6px;
          padding: 10px;
          background-color: rgba(255, 255, 255, 0.8);
        }
      }
      div.bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        @media all and (max-width: 640px) {
         padding: 0 10px;
        }
        div.left {
          span.remainingWords {
            font-size: 12px;
            color: var(--grey-color);
          }
        }
        div.right {
          div.buttonContainer {
            button {
              background-color: var(--button-color);
              padding: 10px 12px;
              color: var(--white-color);
              font-size: 13px;
              cursor: pointer;
              width: 8rem;
            }
            button:disabled,
            button[disabled] {
              background-color: var(--disabled-btn-color);
              cursor: not-allowed;
              color:var(--grey-color);
            }
          }
        }
      }
    }
  }
  