.ontop {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.7);
}
.align-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed !important;
}
.min-ht {
    min-height: 25%
}
div.paymentConfirm {
    text-align: center;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    div.paymentConfirmContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        width: 40%;
        margin: 0 auto;
        position: fixed;
        padding: 34px 20px 30px;
        text-align: center;
        border-radius: 8px;
        z-index: 10;
        @media all and (max-width:1200px){
                width: 45% ;
        }
        
        @media all and (max-width:980px){
                width: 56% ;
        }
        @media all and (max-width:768px){
                width: 70% ;
        }
        @media (max-width: 640px){
            width: 86%;
        }
        @media (max-width: 480px){
                width: 88%;
        }
        h5{
            color: #000;
            margin-bottom: 20px;
            font-size: 26px;
            @media (max-width: 480px){
                font-size: 20px;
            }
        }
        .button-container {
            padding: 10px 0;
        }
        .amount-summary {
            font-family: Arial, sans-serif;
            padding: 0 12px;
            font-size: 16px;
            @media (max-width: 480px){
                font-size: 12.4px;
            }
            .row {
              display: flex;
              justify-content: space-between;
              padding: 18px 0;
              @media (max-width: 480px){
              padding: 8px 0;
              }
              .label {
                text-align: left;
                padding-right: 48px;
              }
              .value {
                text-align: right;
              }
              &.amount-due {
                font-weight: bold;
              }
            }
          }
        div.df{
            display: flex;
            button{
                width: 100%;
                outline: none;
                color: var( --text-color);
                background: var( --text-hover);
                color: #fff;
                padding: 10px 0;
                border-radius: 5px;
                transition: 0.3s ease-in-out;
                margin: 0 22px;
                cursor: pointer;
                &:hover{
                    color: var( --text-color);
                    background: var( --text-hover);
                    transform: scale(1.0476190476,1.0357142857);
                 }
            }
        }
    }
}

div.subscriptionList { 
    padding: 100px 0;
    div.arrowContainer {
        width: 5%;
        cursor: pointer;

        h3 {
            width: 100%;
            background: transparent;
            cursor: pointer;

            svg {
                width: 30px;
                height: 100%;
                fill: white !important;
            }
        }

        &:hover {
            cursor: pointer;
            transform: scale(1.2);
        }
    }
    h1.heading { 
        font-size: 28px;
        text-align: center;
        color: var(--inner-text-color);
    
    }
    div.subscriptionDetails { 
        border: 1px solid var(--border-color);
        box-shadow: rgb(229, 9, 0.8%) 0px 13px 27px -5px, rgb(229, 9, 0.66666666%) 0px 8px 0px -47px;
        padding: 15px 28px;
        margin-top: 50px;
        color: var(--inner-text-color);

        h2.heading { 
            font-size: 22px;
            color: var(--inner-text-color);
        
            text-align: center;
        }
        ul.conditions { 
            list-style: square;
            margin-top: 30px;

            li { 
                line-height: 28px;

                span { 
                    font-size: 16px;
                }
            }
        }
    
    }
    div.skipButton{
        padding:30px 0 0 ;
        display: flex;
        justify-content: flex-end;
        button { 
            display: block;
            border: none;
            outline: none;
            padding: 10px 25px;
            background-color: var(--secondary-bg-color);
            border: 1px solid var(--border-color);
            color: #fff;
            border-radius: 3px;
            transition: 0.5s all ease-in-out;
            cursor: pointer;
            &:hover { 
                background-color: transparent;
               
            }
            span{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            @media all and (max-width:640px){
                width: 100%;
            }
        }
    }
    div.pricing { 
        margin-top: 30px;

        ul { 
            display: flex;
            list-style: none;
            flex-wrap: wrap;

            li { 
                margin-right: 20px;
                margin-bottom: 30px;
                padding: 60px 60px;
                border: 1px solid var(--border-color);
                width: 23.8%;
                text-align: center;
                box-shadow: rgba(229, 9, 20, 0.24) 0px 3px 8px;
                &:last-child{
                    margin-right: 0;
                }
                &:nth-child(4n){
                    margin-right: 0;
                }
                @media all and (max-width:1400px){
                    width: 23.5%;
                }
                @media all and (max-width:1200px){
                    width: 31.5%;
                    &:nth-child(4n){
                        margin-right: 20px;
                    }
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                }
                @media all and (max-width:980px){
                    width: 48%;
                    &:nth-child(3n){
                        margin-right: 20px;
                    }
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                }
                @media all and (max-width:640px){
                    width: 100%;
                    margin-right: 0;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                }
                button { 
                    display: block;
                    border: none;
                    outline: none;
                    padding: 10px 25px;
                    background-color: var(--secondary-bg-color);
                    border: 1px solid var(--border-color);
                    margin: 0 auto 20px;
                    color: #fff;
                    border-radius: 30px;
                    transition: 0.5s all ease-in-out;
                    cursor: pointer;
                    &:hover { 
                        background-color: transparent;
                       
                    }
                }
                span.category { 
                    display: block;
                    margin-bottom: 10px;
                    color: var(--inner-text-color);
                }
                span.price { 
                    display: block;
                    color: var(--inner-text-color);
                    &.discount{
                        span.priceCut{
                            margin-right: 10px;
                            text-decoration: line-through;
                            display: block;
                        }
                    }
                    }
                    span.trialprice{
                        font-weight: bold; 
                        background: #151c30;
                        padding: 10px;
                        color: #d0ac6a;
                        border-radius: 6px;  
                    }
            }
        }
    }
}

/* div.subscriptionList div.subscriptionDetails ul.conditions li::before { 
    content: "-";
    margin-right: 4px;
} */

@media all and (max-width:1200px){
    div.subscriptionList div.subscriptionDetails h2.heading {
        font-size: 20px;
    }
    div.subscriptionList div.subscriptionDetails ul.conditions li {
        font-size: 15px;
    }
}
@media all and (max-width:980px){
    div.subscriptionList div.subscriptionDetails h2.heading {
        font-size: 19px;
    }
}
@media all and (max-width:768px){
    div.subscriptionList div.subscriptionDetails h2.heading {
        font-size: 18px;
    }
}
@media all and (max-width:640px){
    div.subscriptionList div.pricing ul {
        flex-direction: column;
    }
    // div.subscriptionList div.pricing ul li {
    //     margin-right: 0;
    //     width: 80%;
    //     margin: 0 auto 20px;
    // }
    div.subscriptionList div.pricing ul li:last-child {
     
        margin-bottom: 0;
    }
    div.subscriptionList div.pricing ul li button {
        margin: 0 auto 20px;
    }
}
@media all and (max-width:480px){
    div.subscriptionList div.pricing ul li {
        width: 100%;
    }
    div.subscriptionList h1.heading {
        font-size: 25px;
    }
}
@media all and (max-width:360px){
    
}
@media all and (max-width:320px){
    
}
