

div.shortsCardContainer {
  width: 100%;
  position: relative;

  &.showContents {
    cursor: pointer;
    div.premiumStatus {
      div.premium {
        position: absolute;
        top: 6px;
        left: 6px;
        z-index: 8;
        background-color: rgba(8, 25, 43, 0.65);
        // padding: 6px 6px 3px;
        width: 35px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        @media all and (max-width: 1200px) {
          width: 26px;
        }
        svg {
          width: 18px !important;
          height: 18px !important;
          @media all and (max-width: 1200px) {
            width: 12px !important;
            heigth: 12px !important;
          }
        }
      }
      div.free {
        position: absolute;
        top: 6px;
        left: 6px;
        z-index: 8;
        width: 50px;
        @media all and (max-width: 1200px) {
          width: 30px;
        }
        img {
          display: block;
          width: 100%;
        }
      }
      div.lock {
        position: absolute;
        top: 6px;
        left: 6px;
        z-index: 8;
        width: 20px;
        @media all and (max-width: 1200px) {
          width:15px;
        }
        img {
          display: block;
          width: 100%;
        }
      }
    }
    &:hover {
      div.imageContainer {
        img {
          transform: scale(1.1); 
        }

        div.extraActions {
          visibility: visible;
          opacity: 1; 
          animation: fadeIn 0.2s ease-in-out forwards;

          div.showCardOverlay {
            background-color: var(--overlay); 
            z-index: 8;
            div.block-social-info {
              position: absolute;
              bottom: 2.2rem;
              z-index: 999;
              display: flex;
              justify-content: space-between;
              opacity: 1; 
              transition: opacity 0.2s ease-in-out;
              background: transparent;
              padding: 10px 20px;
              width: 100%;
              .share-wrapper {
                position: absolute;
                top: auto;
                right: 0;
                left: 10%;
                bottom: 80%;
                width: 2.375rem;
                height: auto;
                display: none; 
                padding: 10px 260x;
                background-color:#191919;
                div.share-boxs{
                 
                  div.overflow-hidden{
                    display: grid;
                    justify-content: center;
                    margin-top: 20%;
                  }
                }
              }

              .share {
                .share-wrapper {
                  display: none; /* Hide until hover */
                }

                &:hover {
                  .share-wrapper {
                    display: inline-block; 
                    bottom: 100%;
                    left: 8%;
                    width: 15%;
                    @media (min-width: 320px) {
                    
                        width: 30%;
                    
                    }
                    @media (min-width: 360px) {
                    
                        width: 26%;
                        left: 11%;
                      
                    }
                    @media (min-width: 375px) {
                    
                        width: 26%;
                  
                    }
                    @media (min-width: 425px) {
                    
                      width: 23%;
                
                    }
                    @media (min-width: 480px) {
                     
                        width: 31%;
              
                    }
                    @media (min-width: 640px) {
                        left: 8%;
                        width: 23%;
                   
                    }
                    @media (min-width: 768px) {
                    
                        width: 25%;
                        left: 9%;
                   
                    }
                    @media (min-width: 980px) {
                        width: 20%;
                        left: 9%;
                   
                    }
                    @media (min-width: 1024px) {
                      width: 18%;
                    }
                    @media (min-width: 1200px) {
                  
                        width: 19%;
                   
                    }
                    @media (min-width: 1400px) {
                    
                      width: 15%;
                      left: 8%;
                    }
                   @media (min-width: 2560px) {
                    width: 9%;
                  left: 6%;
                   }
                 
                }
              }
              }

              .music-play-lists {
                display: flex;
                width: 100%;
                li {
                  width: 22%;
                  height: 40px;
                  margin-bottom: 0.4375rem;
                  border-radius: 50%;
                  background-color: transparent;
                  border: 1px solid var(--white);
                  color: white !important;
                  transition: all 0.3s ease;
                  display: flex;
                  justify-content: center; 
                  align-items: center;
                  transition: all 0.3s ease;
                  @media (max-width: 2600px) {
                    height: 45px;
                    width: 15%;
                     }
                  @media (max-width: 1980px) {
                    width: 20%;
                       }
                  @media (max-width: 1600px) {
                        width: 25%;
                           }
                  @media (max-width: 1400px) {
                    width: 28%;
                     }
                  @media (max-width: 1200px) {
                    height: 40px;
                       }
                     
                  @media (max-width: 980px) {
                    width: 30%;
                    height: 35px;
                       }
                  @media (max-width: 768px) {
                    height: 30px;
                     }
                  @media (max-width: 480px) {
                    width: 45%;
                       }
                     
                  span.shareIcon{
                   
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    border: 1px solid white !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
   
                    svg{
                      fill: white !important;
                      width: 70%;
                    }
                  }
                  &:hover {
                    background-color:var(--button-color); 
                    border: none;
                  }

                  .share > span {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    font-size: 1rem;
                    line-height: 1.625rem;
                  }

                  i {
                    color: var(--bs-white);
                    font-size: 0.945em;
                    line-height: 1.625rem;
                    cursor: pointer;
                    &:hover {
                      color:var(--button-color);
                    }
                  }
                }
               
              }

              div.iq-button {
                margin-left: auto;
                display: flex;
                justify-content: flex-end;
                background-color: var(--button-color);
                width: 22%;
                height: 40px;
                border-radius: 50%;
                justify-content: center; 
                align-items: center;
                @media (max-width: 2600px) {
                  height: 45px;
                  width: 15%;
                   }
                @media (max-width: 1980px) {
                    width: 20%;
                     }
                @media (max-width: 1600px) {
                      width: 25%;
                         }  
                @media (max-width: 1400px) {
                  width: 28%;
                   }
                @media (max-width: 1200px) {
                    height: 40px;
                       }
                @media (max-width: 980px) {
                  width: 30%;
                  height: 35px;
                     }
                @media (max-width: 768px) {
                  height: 30px;
                       }
                @media (max-width: 480px) {
                        width: 45%;
                           }        
                &:focus{
                  background-color: transparent;
                  width: 20%;
                  border-radius: 50%;
                  border: 2px solid var(--white);
              }
              }
              &:focus{
                background-color: transparent;
                width: 20%;
                border-radius: 50%;
                border: 2px solid var(--white);
            }
              div.iq-button:hover {
                color:gray;
                border-radius: 50%;
            
                background-color: var(--button-hover);
                &:focus{
                  background-color:gray;
                 
              }
              }
              
            }
          }
        }
      }

      div.metaData {
        h1.title {
          color: var(--text-hover);
        }
      }
    }
  }

  div.imageContainer {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 3px;

    img {
      width: 100%;
      height: 25rem;
      display: block;
      border-radius: 3px;
      transition: transform 0.2s ease-in-out;
      @media (max-width: 980px) {
        height: 20rem;
      }
      @media (max-width: 768px) {
        height: 15rem;
      }
      @media (max-width: 480px) {
        height: 12rem;
      }

      &.landscape {
        min-height: 130px;
        background: black;
      }

      &.portrait {
        min-height: 420px;
        background: black;
      }
    }

    div.extraActions {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s ease-in-out; 

      div.showCardOverlay {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: var(--overlay);
        z-index: 8;
        // width: 45px;
        // height: 45px;
      }
    }
  }

  div.metaData {
    margin-top: 10px;
    color: var(--inner-text-color);

    h1.title {
      color: var(--text-color);
      font-size: 16px;
      transition: color 0.3s ease;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @media (max-width: 320px) {
        font-size: 12px;
      }

      @media (max-width: 640px) {
        font-size: 12px;
      }
    }

    div.datas {
      display: flex;
      justify-content: space-between;

      @media (max-width: 980px) {
        display: none;
      }

      div.left {
        span {
          font-size: 12px;
          margin-right: 6px;

          &.duration.showDot::before {
            content: "·";
            margin-right: 6px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}



  