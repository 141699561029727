div.accountSettings { 
  padding: 130px 0;
  position: relative;
  span.error{
	font-size: 14px;
    color: #E72A31;
    display: block;
    margin-top: 10px;
	font-weight: normal !important;
}
	div.wrapper { 

		div.head { 

			h1.heading { 
				font-size: 34px;
          padding-bottom: 10px;
		  color: var(--text-color);
		  text-align: center;
		  @media all and (max-width:640px){
			font-size: 28px;
		}
			}
			@media all and (max-width:640px){
				padding-left: 20px;
			}
		}

		div.contents { 
			display: flex;
			justify-content: space-between;
			width: 80%;
			margin: 0 auto;
			padding-top: 100px;
			@media all and (max-width:1200px){
				width: 90%;
			}
			@media all and (max-width:980px){
				width: 100%;
			}
			@media all and (max-width:768px){
				flex-direction: column;
				justify-content: center;
			}
			@media all and (max-width:640px){
				padding: 80px 20px;
			}
			div.left { 
				width: 40%;
				@media all and (max-width:768px){
					width: 100%;
					margin-bottom: 20px;
				}
				div.profileImageContainer { 
					width: 250px;
					text-align: center;
					@media all and (max-width:768px){
						margin:  0 auto;
					}
					img { 
						display: block;
						width: 100%;
						border-radius: 50%;
						height: 250px;
						object-fit: cover;
					}
					input{
						display: none;
					}

					label { 
						color: var(--button-color);
						font-size: 14px;
						display: inline-block;
						margin-top: 15px;
						cursor: pointer;
						font-weight: 600;
					}
				}
			}

			div.right { 
				width: 55%;
				@media all and (max-width:768px){
					width: 100%;
				}
				div.formSection { 

					form { 
						input,select{
							outline: none;
							border-radius: 5px;
							font-size: 14px;
							background: transparent;
							text-align: left;
						  padding: 20px 15px;
						  border: 1px solid var(--inner-text-color);
						  caret-color: var(--text-color);
						  display: block;
						  width: 100%;
						  color: var(--text-color);
						  &:focus{
							  border: 1px solid var(--text-color);
						  }
						  @media all and (max-width:640px){
							  padding: 16px 15px;
						  }
						}
						select{
							appearance: none;
							color: var(--text-color) !important;
							option{
								color: #000 !important;
							}

						}
						label{
							color: var(--inner-text-color);
							font-size: 16px;
							display: inline-block;
							margin-bottom: 10px;
						}
						
						span.success{
							font-size: 12px;
						}
						

						div.half { 
							display: flex;
							justify-content: space-between;
							margin-bottom: 30px;
							@media all and (max-width:480px){
								flex-direction: column;
								margin-bottom: 20px;
							}
							div.fname,div.email { 
								width: 49%;
								
								@media all and (max-width:480px){
									width: 100%;
									margin-bottom: 20px;
								}
								
							}
							div.email{
								input{
									color: var(--inner-text-color);
								}
							}

							div.lname,div.country { 
								width: 49%;
								@media all and (max-width:480px){
									width: 100%;
								}
								label { 

								}

								#lname { 

								}
							}
						}

						div.full { 
							span.cpassword{
								display: inline-block !important;
							}
							div.phone { 
								width: 49%;
								@media all and (max-width:480px){
									width: 100%;
								}
								label { 

								}

								#password { 

								}
							}

							span { 
								color: var(--button-color);
								font-size: 14px;
								display: inline-block;
								margin-top: 10px;
							}
						}
						span{
							display: block !important;
							font-weight: 600;
							cursor: pointer;
						}
						div.buttonContainer { 
							display: flex;
						padding: 18px 20px;
						border-radius: 4px;
						align-items: center;
						margin-bottom: 15px;
						cursor: pointer;
						transition: 0.2s ease-in-out;
						justify-content: center;
						position: relative;
						width: 20rem;
						margin: 30px auto 0;
						@media all and (max-width:640px){
							width: 15rem;
						}
						@media all and (max-width:480px){
							width: 10rem;
						}
						&:hover{
							div.background{
			
								transform: scale(1.0476190476,1.0357142857);
							}
						}
							div.background{
								background: var(--button-color);
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								border-radius: 4px;
								transition: 0.1s ease-in-out;
								z-index: 1;
			
							}
							button { 
								background: transparent;
								font-size: 15px;
								color: var( --text-color);
								font-weight: 600;
								position: relative;
								z-index: 2;
								cursor: pointer;
			
			
							}
						}
					}
					.template-9-font {
						input, select {
						  font-family: 'Gilroy-Medium' !important;
						}
					  }
					  
				}
			}
		}
		div.planDetails{
			width: 80%;
			margin: 30px auto 0;
			padding: 20px 0 0;
			border-top: 1px solid var(--border-color);
			display: flex;
			justify-content: space-between;
			@media all and (max-width:768px){
				width: 100%;
			}
			@media all and (max-width:480px){
				flex-direction: column;
			}
			div.leftContainer{
				width: 30%;
				@media all and (max-width:480px){
					margin-bottom: 20px;
					width: 100%;
				}
				h4.heading{
					color: var(--inner-text-color);
					font-size: 13px;
					font-weight: normal;
				}
			}
			div.rightContainer{
				width: 70%;
				display: flex;
				justify-content: space-between;
				@media all and (max-width:768px){
					flex-direction: column;
				}				
				div.left{
					@media all and (max-width:768px){
						margin-bottom: 20px;
					}
					p{
						color: #E72A31;
					}
					h2.planName{
						color: var(--text-color);
						font-size: 14px;
					}
						div.buttonContainer { 
							display: flex;
						padding: 12px 20px;
						border-radius: 4px;
						align-items: center;
						margin-bottom: 15px;
						cursor: pointer;
						transition: 0.2s ease-in-out;
						justify-content: center;
						position: relative;
						width: 15rem;
						margin: 20px auto 0;
						@media all and (max-width:768px){
							margin: 20px 0 0;
						}
						@media all and (max-width:640px){
							width: 12rem;
						}
						@media all and (max-width:480px){
							width: 15rem;
						}
						&:hover{
							div.background{
			
								transform: scale(1.0476190476,1.0357142857);
							}
						}
							div.background{
								background: #E72A31;
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								border-radius: 4px;
								transition: 0.1s ease-in-out;
								z-index: 1;
			
							}
							button { 
								background: transparent;
								font-size: 15px;
								color: var( --text-color);
								font-weight: 600;
								position: relative;
								z-index: 2;
								cursor: pointer;
			
			
							}
						}
				}
				div.right{
					span{
						display: block;
						color: var(--text-hover);
						font-size: 14px;
						font-weight: 600;
						margin-top: 10px;
						cursor: pointer;
						&:first-child{
							margin-top: 0;
						}
					}
				}

			}
		}

		div.settings{
			width: 80%;
			margin: 30px auto 0;
			padding: 20px 0;
			border-top: 1px solid var(--border-color);
			display: flex;
			justify-content: space-between;
			@media all and (max-width:768px){
				width: 100%;
			}
			@media all and (max-width:480px){
				flex-direction: column;
			}
			div.leftContainer{
				width: 30%;
				@media all and (max-width:480px){
					margin-bottom: 20px;
					width: 100%;
				}
				h4.heading{
					color: var(--inner-text-color);
					font-size: 13px;
					font-weight: normal;
				}
			}
			div.rightContainer{
				width: 70%;
				ul{
					li{
						margin-top: 10px;
						&:first-child{
							margin-top: 0;
						}
						span{
							display: inline-block;
							color: var(--button-color);
							font-size: 14px;
							font-weight: 600;
							cursor: pointer;
							
						}
					}
				}
					
				

			}
		}
	}
}