.iq-top-ten-block-slider {
  margin: 0 -0.9375em;

  .swiper-wrapper {
    margin: 0;
  }

  .swiper-button {
    &.swiper-button-next {
      right: 2rem;
    }

    &.swiper-button-prev {
      left: 2rem;
    }
  }
}

.iq-top-ten-block {
  .block-image {
    padding: 0 0.375rem;

    .cardContainer {
      position: relative;

      .overly-images {
        position: relative;
        z-index: 1;
        display: block;

        img {
          width: 100%;
          transition: all 0.6s ease 0s;
          border-radius: 2%;
        }
      }

      .top-ten-numbers {
        position: absolute;
        bottom: -60px;
        right: 0;
        font-weight: 800;
        font-size: 8em;
        transition: all 0.4s ease-out;
        z-index: 2;

        @media (max-width: 991px) {
          font-size: 6.5rem;
        }

        @media (max-width: 767px) {
          font-size: 4.5rem !important;
          bottom: -40px;
        }
      }
      .top-ten-numbers {
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        line-height: normal;
        background-image: url("/src/assets/Images/texure.webp");
      }
      .top-ten-numbers-extra {
        color: var(--black-color); 
        background-color:transparent;
        text-shadow: 
          -2px -2px 0 white,  
          2px -2px 0 white,  
          -2px 2px 0 white,  
          2px 2px 0 white;    
        padding: 2px 4px; 
        border-radius: 4px; 
      }
      
    }
    .metaData{
      h1.title {
        margin-top: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        padding: 0 10px;
        color: var(--text-color);
        font-size: 15px;
        cursor: pointer;
       &:hover{
          color: var(--text-hover);
        }
        position: relative;
        &::after {
          content: attr(title);
          position: absolute;
          bottom: 100%; 
          left: 50%;
          transform: translateX(-50%);
          background: rgba(0, 0, 0, 0.7);
          color: white;
          padding: 5px 10px;
          border-radius: 5px;
          white-space: nowrap;
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.3s;
          z-index: 9999;
          font-size: 0.9rem; 
        }
        &:hover::after {
          opacity: 1;
        }
        @media (max-width: 1980px) {
          font-size: 14px;
        }
      }
    }
  }
  &:hover {
    .block-image {
      .top-ten-numbers {
        bottom: -50px;
       
        }       
    }
  }

  &.top-ten-portrait,
  &.top-ten-landscape {
    .block-image {
      padding: 0 0.275rem;
    }
  }
}

@media (max-width: 767px) {
  .iq-top-ten-block {
    .block-image {
      padding: 0 0.4687rem;
    }

    .top-ten-numbers {
      font-size: 4.5rem !important;
      bottom: -40px;
    }
  }

  .iq-top-ten-block-slider {
    .swiper-button {
      &.swiper-button-next {
        right: 1rem;
      }

      &.swiper-button-prev {
        left: 1rem;
      }
    }
  }
}
