div.showsRow {
    padding-top: 40px;
    h1.heading {
      font-size: 25px;
      color: var(--text-color);
      margin-bottom: 20px;
    }
    li.showall{
        margin-bottom: 10px;   
        button{
          background-color: transparent;
          color: var(--text-color);
          font-size: 13px;
          cursor: pointer;
          &:hover {
            color: var(--text-hover);
          }       
        }
    }
    div.seasonContainer{
      border: 1px solid var(--border-color);
      border-radius: 8px;
      width: 20rem;
      margin-bottom: 20px;
      background: transparent;
      padding: 15px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      color: var(--text-color);
      cursor: pointer;
      @media all and (max-width:480px){
        width: 100%;
      }
      svg{
        width: 26px !important;
        height: 26px !important;
        transition: 0.3s ease-in-out;
        &.active{
          transform: rotate(-180deg);
        }
      }
      &.zIndexController{
        div.listContainer{
          z-index: 9;
        }
      }
      div.listContainer{
        
        height: 200px;
        overflow-y: scroll;
        position: absolute;
        top: 55px;
        width: 100%;
        left: 0;
        right: 0;
        z-index: -1;
        -ms-overflow-style: none ;  /* IE and Edge */
        scrollbar-width: none ;  /* Firefox */
        &::-webkit-scrollbar {
          display: none ;
        }
        &.scroll{
        -ms-overflow-style: unset ;  /* IE and Edge */

          scrollbar-width: unset ;  /* Firefox */
        &::-webkit-scrollbar {
          display: block ;
        }
        &::-webkit-scrollbar{
          width: 5px;
          border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb{
          background-color: var(--bg-color);
          border-radius: 30px;
        }
        &::-webkit-scrollbar-track{
          background-color: var(--grey-color);
        }
        }
        cursor: pointer;
      }
      div.list{
       
        
        // border: 1px solid var(--border-color);
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        display: none;
       
        &.active{
          display: block;
        }
        div.item{
          width: 100%;
          padding: 8px 10px;
          border-bottom: 1px solid var(--border-color);
          background-color: #1c1f29;
          &:last-child{

            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          span{
            font-size: 16px;
            color: var(--text-color);
          }

        }

      }
    }
  }