div.articleInfo { 
    padding: 130px 0 80px;
	div.wrapper { 
        width: 80%;
		h1.heading { 
            font-size: 32px;
            color: var(--text-color);
            @media all and (max-width:640px){
                padding-left: 20px;
            }
		}

		div.dateSection { 
            display: flex;
            align-items: center;
            margin-top: 30px;
            @media all and (max-width:640px){
                padding-left: 20px;
            }
			span.icon { 
                width: 20px;
                display: block;
                margin-right: 10px;
				img { 
                    display: block;
                    width: 100%;
				}
			}

			span.date { 
                font-size: 12px;
                color: var(--text-hover);
                
			}
		}

		div.articleDetails { 
            display: flex;
            justify-content: space-between; 
            flex-direction: column;
            margin: 20px auto 0;
            @media all and (max-width:980px){
                width: 90%;
            }
            @media all and (max-width:768px){
                flex-direction: column;
            }
			div.left { 
                width: 100%;
                margin-bottom: 30px;
                @media all and (max-width:768px){
                    width: 100%;
                    margin-bottom: 30px;
                }
				div.imageContainer { 
                    width: 100%;
                    margin: 0 auto;
					img { 
                        display: block;
                        width: 100%;
                        object-fit: cover;
					}
				}
			}

			div.right { 
                width: 100%;
                @media all and (max-width:768px){
                    width: 100%;
                }
				p { 
                    font-size: 15px;
                    margin-bottom: 20px;
                    color: var(--inner-text-color);
                    &:last-child{
                        margin-bottom: 0;
                    }
                    @media all and (max-width:980px){
                        font-size: 13px;
                    }
				}
			}
		}

		div.videoContainer { 
            width: 80%;
            margin: 90px auto 0;
			video { 
                display: block;
                width: 100%;
			}
            @media all and (max-width:980px){
                width: 90%;
            }
		}

		div.buttonContainer { 
            text-align: center;
            margin-top: 50px;
            button { 
    
                background-color: transparent;
                border: 1px solid var(--primary-color);
                padding: 6px 10px;
                width: 15%;
                border-radius: 10px;
                color: var(--primary-color);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                transition: 0.4s ease-in-out;
                @media all and (max-width:980px){
                    width: 20%;
                    font-size: 14px;
                }
                @media all and (max-width:641px){
                   width: 25%;
                }
                @media all and (max-width:480px){
                    width: 35%;
                    padding: 6px 15px;
                 }
                span{
                    color: var(--primary-color);
                    margin :0 10px;
                    transition: 0.4s ease-in-out;
                }
                svg{
                    transition: 0.4s ease-in-out;
                }
                &:hover{
                    border-color: var(--icons-color);
                    span{
                        color: var(--icons-color);
                    }
                    svg{
                       fill: var(--icons-color);
                    }
                }
            }
        }
	}
}