div.customHeaderComponent{
    background-color: var(--custom-header-bg-color);
    position: sticky;
    top: 0;
    z-index: 99;
    height: 70px;
   display: flex;
   align-items: center;
    div.wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        @media all and (max-width:1200px){
            width: 98%;
        }
    }
    div.left{
        @media all and (max-width:980px){
            display: flex;
            align-items: center;
          }
        div.hamburgerMenuIconContainer{
            display: none;
            margin-right: 15px;
            @media all and (max-width:980px){
              display: block;
              svg{
                width: 30px !important;
                height: 30px !important;
                color: var(--white-color);
                stroke: var(--white-color) !important;
                fill: var(--white-color) !important;
              }
            }
        }
        h1.logo{
            width: 100px;
            cursor: pointer;
            img{
                width: 100%;
                display: block;
            }
        }
    }
    div.center{
        height: 100%;
        @media all and (max-width:980px){
            display: none;
        }
        ul.navlinks{
            display: flex;
            align-items: center;
            height: 100%;
    
            li.link{
                list-style: none;
                cursor: pointer;
                color: var(--text-color);
                font-weight: 500;
                padding: 0 20px;
                height: 100%;
                display: flex;
                align-items: center;
                transition: 0.3s ease-in-out;
                font-size: 17px;
                position: relative;
                @media all and (max-width:1200px){
                    padding: 0 11px;
                }
                i{
                    margin-left: 10px;
                }
                &:last-child{
                    margin-right: 0;
                }
                &:hover{
                    background-color: var(--custom-top-banner-bg-color);
                }
                &.active{
                    background-color: var(--custom-top-banner-bg-color);

                }
                &.allShows{
                    &:hover{
                        ul.childNavs{
                        
                            display: block;
                        }
                    }
                }
                &.account{
                    ul.childNavs{
                        width: 12rem;
                    }
                    &:hover{
                        ul.childNavs{
                        
                            display: block;
                        }
                    }
                }
                &.products{
                    &:hover{
                        ul.childNavs{
                        
                            display: block;
                        }
                    }
                }
                &.contentProducers{
                  
                }
                ul.childNavs{
                    position: absolute;
                    top:100%;
                    width: 15rem;
                    background-color: var(--custom-header-bg-color);
                    z-index: 99;
                    left: 0;
                    overflow-y: scroll;
                    -ms-overflow-style: none ;  /* IE and Edge */
                    scrollbar-width: none ;  /* Firefox */
                    display: none;
                    animation: fadeIn 0.4s ease-in-out;
                    &::-webkit-scrollbar {
                      display: none ;
                    }
                  
                    &.scroll{
                    height: 400px;

                    -ms-overflow-style: unset ;  /* IE and Edge */
            
                      scrollbar-width: unset ;  /* Firefox */
                    &::-webkit-scrollbar {
                      display: block ;
                    }
                    &::-webkit-scrollbar{
                      width: 5px;
                      border-radius: 30px;
                    }
                    &::-webkit-scrollbar-thumb{
                      background-color: var(--custom-top-banner-bg-color);
                      border-radius: 30px;
                    }
                    &::-webkit-scrollbar-track{
                      background-color: var(--custom-header-bg-color);
                    }
                    }
                    li.childLink{
                        border: 1px solid var(--white-color);
                        border-right: none;
                        border-left: none;
                        border-top: none;
                        padding: 10px 10px;
                        &:last-child{
                            border-bottom: none;
                        }
                        &:hover{
                            background-color: var(--custom-top-banner-bg-color);
                        }
                        
                    }

                }
            }
    
        }
    }
    div.right{
        height: 100%;
        background-color: #ee0909;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 150px;
        cursor: pointer;
        @media all and (max-width:1200px){
            width: 130px;
        }
        div.liveLogo{
            width: 35px;
            img{
                display: block;
                width: 100%;
            }
           
        }
        span.live{
            font-weight: 900;
            font-size: 22px;
            color: var(--white-color);
            @media all and (max-width:1200px){
                font-size: 18px;
            }
        }
    }

    div.hambergurMenu{
        display: none;
        &.showMenu{
            @media all and (max-width:980px){
                display: block;
            }
            background-color: var(--white-color);
            position: absolute;
            top: 100%;
            width: 100%;
            ul.navlinksResponsive{
                li.link{
                    padding: 10px 15px;
                    // transition: 0.4s ease-in-out;
                    &.active{
                        background-color: var(--custom-top-banner-bg-color);
                        span{
                            color: var(--white-color);
                        }
                        ul.childLinks{
                            display: block;
                        }
                        div.expandLink{
                            i{
                                color: var(--white-color);
                            }
                        }
                    }
                    &:hover{
                        background-color: var(--custom-top-banner-bg-color);
                        span{
                            color: var(--white-color);
                        }
                        div.expandLink{
                            i{
                                color: var(--white-color);
                            }
                        }
                    }
                    span{
                        color: var(--custom-top-banner-bg-color);
                        font-weight: 600;
                    }
                    div.expandLink{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    ul.childLinks{
                        overflow-y: scroll;
                        -ms-overflow-style: none ;  /* IE and Edge */
                        scrollbar-width: none ;  /* Firefox */
                        animation: fadeIn 0.4s ease-in-out;
                        display: none;
                        &::-webkit-scrollbar {
                          display: none ;
                        }
                      
                        &.scroll{
                        height: 200px;
    
                        -ms-overflow-style: unset ;  /* IE and Edge */
                
                          scrollbar-width: unset ;  /* Firefox */
                        &::-webkit-scrollbar {
                          display: block ;
                        }
                        &::-webkit-scrollbar{
                          width: 5px;
                          border-radius: 30px;
                        }
                        &::-webkit-scrollbar-thumb{
                          background-color: var(--custom-header-bg-color);
                          border-radius: 30px;
                        }
                        &::-webkit-scrollbar-track{
                          background-color: var(--custom-top-banner-bg-color);
                        }
                        }
                        li.childLink{
                             padding: 10px 15px;
                             border-bottom: 1px solid var(--white-color);
                             background-color: var(--custom-top-banner-bg-color);
                            &:first-child{
                             padding: 20px 15px 10px;

                            }
                            &:last-child{
                                border-bottom: none;
                            }
                             span{
                                color: var(--white-color);
                            }
                        }
                    }
                }
            }
            
        }
    }

}